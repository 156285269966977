.container{height:400px; width:400px; margin:auto;margin-top: 50px;position:relative}
.container img{height: 100%; width: 100%;}

.child{
position: absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
transition: .5s ease;
background-color: rgba(255,0,0,.5);
transform: scale(0);

}

.text{ font-size: 18px; color: white; font-weight: bold; position:absolute; top:50%; left:50%; transform: translate(-50%,-50%);}

.container:hover .child{transform: scale(1);}



/* 

.container{height:400px; width:400px; margin:auto;margin-top: 50px;position:relative}
.container img{height: 100%; width: 100%;}

.child{
position: absolute;

right:0;
bottom:0;
width:0;
height:0;
transition: 1s ease;
background-color: rgba(0,0,0,.5);

overflow: hidden;
transform: translate(-50%,-50%);
}

.text{ font-size: 18px; color: white; font-weight: bold; position:absolute; top:50%; left:50%; transform: translate(-50%,-50%); white-space: nowrap;}

.container:hover .child{transform: rotate(360deg);width:100%; height:100%} */