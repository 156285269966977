.icon {
   position: absolute;
    margin-top: 50px;
    top:40%;
    left:50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}

/* .icon div{height:5px; width:35px; margin: 5px; background-color: black;} */
 .bar1, .bar2, .bar3 {
       width: 35px;
       height: 5px;
       background-color: #333;
       margin: 6px 0;
       transition: 0.4s;
     }
    