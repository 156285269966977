body{background-color: white;}
.nav{position: absolute;top:0; left:0;width:200px;background-color: rgb(35, 35, 35);
color:rgb(164, 161, 161);height:100%;padding-top: 40px;}
.main{padding:20px;margin-top: -20px;}
/* .main section {display: grid; grid-template-columns: 1fr 1fr; gap:20px; gap:20px;} */

.nav div{padding:6px 6px 6px 20px;font-size: 18px;}
.nav div:hover {color:white;cursor: pointer;}

.main p{background-color: rgb(216, 218, 220);padding:15px;}

.close{position: absolute;top:20px; right:20px;padding:5px 10px; }
.close:hover {color:white;cursor: pointer;background-color: rgba(0,0,0,.5);}
