@keyframes boxAnimation {
0%{background-color: red;left:0;top:0;}
    25% {background-color: orange;left:300px;top:0;}
    50% {background-color: blue; left:300px; top:300px;}
75% {background-color: green; left:0;top:300px;}
100%{background-color: red;left:0; top:0;}

}


.box{position: relative;width:100px; height:100px; margin:auto;margin-top: 40px;animation: boxAnimation;
    background-color: red;
animation-duration: 5s;
animation-iteration-count: 3;
animation-delay:1s;
animation-direction:alternate;
animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}