.container{width:400px; height: 400px;margin:auto; margin-top: 50px;position: relative;}
.container img{height:100%; width:100%}

.text{
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    color:white;
    background-color: rgba(0,0,0,.5);
    transition: .5 ease;
    opacity: 0;
    bottom:0;
    width:100%;
padding-top: 10px;
padding-bottom: 10px;
    text-align: center;
}


.container:hover .text{
    opacity: 1;
}