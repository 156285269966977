.Overlay{height:100%; width:100%;
position:fixed;
top:0px; left:0px; right:0px; bottom:0;
 background-color: rgba(0,0,0,0.5);
cursor: pointer;
z-index: 2;

}

.text{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    font-size: 25px;
    color:white;

}


/* 
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
  } */
  
  /* #text{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  } */