.container {height:300px; width:300px; margin:auto; margin-top:50px;position:relative}
.container img{height:100%; width:100%}

.child{
position: absolute;
width: 100%;
height:100%;
top:0;
bottom:0;
left:0;
right:0;
transition:.5 ease;
opacity: 0;
background-color: red;
transition: .5s ease;
transform-style:preserve-3d;
}

.icon{
    position: absolute;
    font-size: 50px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color:white;

}

.container:hover .child{
    opacity:1;
    /* transform: scale(2); */
    transform:rotateY(180deg)
}
.container:hover .container img{opacity: 0;}