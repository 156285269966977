.container{width:400px; height:400px;margin:auto; margin-top: 50px; position: relative;}
.container img{height: 100%; width:100%}

.child{
    position: absolute;
    height:100%;
    width: 0;
    right:100%;
    left:0;
    top:0;
    bottom:0;
    background-color: red;
    cursor: pointer;
    transition: .5s ease;
    /* opacity: 0; */
overflow: hidden;
}

.container:hover .child{width:100%;right:0;}

.text {
    font-size: 18px;
    font-weight: bold;
    color:white;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}