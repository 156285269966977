.container{height:350px; width:400px; margin: auto; margin-top: 50px; position: relative;}
.container img{height:100%; width:100%;}

.child{
    /* opacity: 0; */
position: absolute;
top:100%;
right:0;
left:0;
overflow: hidden;
bottom:0;
height:0%;
width:100%;
background-color: red;
transition:.5s ease;
}

.text{
    font-size: 18px;
    font-weight: bold;
    color: white;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
}

.container:hover .child{height:100%;top:0;}
