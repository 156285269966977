.container{height:250px; width:300px;margin:auto;position: relative;}
.container img{height: 100%; width:100%}

.child{position: absolute; background-color:rgba(63, 255, 239,.5); cursor: pointer; height:100%; width:100%; top:0; right:0; left:0; bottom:0;  transition: .5s ease; opacity: 0;}

.container:hover .child{opacity: 1;}

.child button {
    font-size: 18px;
    color:white;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
   background-color:red; border:none; color:white;
   padding:5px 10px; border-radius: 10px;
}

.child button:hover { background-color: rgb(208, 0, 0);cursor: pointer;}