/* body{width:100vw; height:100vh; margin:0; background-color: teal;} */

.container{width:100vw; height:100vh;background-color: purple; display: flex; overflow-x: scroll; scroll-snap-type: x mandatory; }


.container section{display: flex; justify-content: center; align-items: center;
height:100%; width:calc(100% - 100px); scroll-snap-align: start; flex:none;
color:yellow; font-size:100px; font-family: Arial, Helvetica, sans-serif;
scroll-margin-left: 100px;
}

.container section:nth-child(1){
    background-color: teal;
}

.container section:nth-child(2){
    background-color: blueviolet;
}

.container section:nth-child(3){
    background-color: brown;
}

.container section:nth-child(4){
    background-color: rgb(34, 34, 34);
}