.container{margin:auto;display: grid; grid-template-columns: 200px 1fr;height:100vh;background-color: orange;}
.left{ background-color: rgb(54, 54, 54);display: flex; flex-direction: column;  font-size: 18px; color:white}
.left div{padding:10px; padding-left: 20px;}
.left img{height:60px; width:150px;padding-left: 20px; padding-top: 10px; padding-bottom: 10px;}
.left div{font-size: 18px; font-weight: bold;cursor: pointer;}
.left div:hover{background-color: black;}
.left input{padding:7px; font-size: 18px; border:none; outline:none;padding-left: 20px;}

.right{ padding:20px;background-color: rgb(35, 35, 35); color:white}

/* .box{display: flex; flex-direction: column;gap:20px;} */
.box{display: grid; grid-template-columns: 1fr 1fr; gap:20px;}
.box section {padding:20px; border:none; background-color: rgb(54, 54, 54);}
.name{margin-left:20px;}

.layout{height:100vh; width:100vw;display: flex; overflow-x:auto; scroll-snap-type: x mandatory;}
.layout>div{scroll-snap-align:start;height: 100vh; width:calc(100vw - 60px);flex:none; scroll-margin-left: 60px;}

