*{padding:0;margin:0;box-sizing: border-box;}


.Sidebar{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0; left:0;
    height:100%;
    transition: .5s;
    overflow:auto;
    scroll-behavior: smooth;
    white-space: nowrap;
}
.Sidebar::-webkit-scrollbar{display: none;}


.content{padding-right:5%;padding-left:5%;background-color: rgb(248, 248, 248);}
.xmark{position: absolute;right:20px; top:20px;font-size: 20px;padding:5px;padding-right:10px; padding-left:10px;}

.Sidebar>img{padding-left:20px;}
.Sidebar>div,.Sidebar tr{color:white;font-size: 20px;color:black; }
.Sidebar tr>td:nth-child(1){padding-left:20px;width:40px;}
.Sidebar tr>td:nth-child(2){padding-left:10px;}
/* .Sidebar>div:nth-child(1){margin-top: 50px;} */

.Sidebar tr:hover{color:white; width:100%; cursor: pointer;background-color: blue;}

.Sidebar tbody, .Sidebar table{width:100%;}
.Sidebar td{padding:3px;}

.xmark:hover {cursor: pointer;}

.menu{cursor: pointer;}