body{background-color: rgb(227, 227, 227);}
.carousel{  margin:auto;display: flex; background:white;overflow-x: hidden;scroll-snap-type: x mandatory; scroll-behavior: smooth;align-items: center;}
.carousel div{width:16%;margin:2%; height:200px;color:white;
    display: flex; align-items: center; justify-content: center; font-size: 30px; font-weight: bold;
    background-color: teal;flex:none; scroll-snap-align: center ;}

    .container{display: flex;  align-items: center;width:90%;margin:auto;margin-top: 100px;position: relative;background-color: teal;}
    .left{position: absolute;left:10px;}
    .right{position: absolute; right:10px;}
    .left,.right{background-color: black;color:white;padding:10px 12px;  border-radius: 50%;cursor: pointer;}
    .left:hover, .right:hover {background-color: red; color:white}