.box{height:500px; width:500px;margin:auto;position:relative}

.yes{
    height:100%; width:100%;
}
.container{
    position: absolute;
    right:0;
    left:100%;
    top:0;
    bottom:0;
    height:100%;
    width:0;
    overflow: hidden;
    background-color: rgb(255, 0, 0);
    cursor: pointer;
    transition: .5s ease;
    /* opacity: 0; */
    /* z-index: 5; */
}
.box:hover  .container{width:60%; left:40%}

.text{
    font-size: 30px;
    font-weight: bold;
    color:white;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
}
